/**
 * Display logic which shows the element if the user has the
 * specified role(s) attr.roles which takes
 * accepts a string array of roles e.g roles='["BUSINESS_ADMIN", "BUSINESS_BOOKING"]'
 */
angular.module("app")
    .directive("hasRole", ["$log", "$animate", "authenticatedStatus", function ($log, $animate, authenticatedStatus) {

        return {
            restrict: 'A',
            multiElement: true,

            link: function (scope, element, attr) {

                var roles = attr.roles ? angular.fromJson(attr.roles) : [];
                scope.$watch(function(scope){
                    return authenticatedStatus.directUser;
                }, function(newValue){
                    var hasAccess = hasRoleAccess(roles, newValue);
                    $animate[hasAccess ? 'removeClass' : 'addClass'](element, 'ng-hide', function () {
                        return {tempClasses: 'ng-hide-animate'};
                    });
                });
            }
        };

        function hasRoleAccess(requiredRoles, user) {
            if (user) {
                var foundRole = _.find(user.roles, function (role) {
                    return _.includes(requiredRoles, role);
                });
                return foundRole !== undefined;
            }
            return false;
        }


    }])
;
